import { render, staticRenderFns } from "./Shops.vue?vue&type=template&id=75337924&scoped=true"
import script from "./Shops.vue?vue&type=script&lang=js"
export * from "./Shops.vue?vue&type=script&lang=js"
import style0 from "./Shops.vue?vue&type=style&index=0&id=75337924&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75337924",
  null
  
)

export default component.exports