<template>
  <div>
    <bread-crumb
      :name="
        shops.length > 0
          ? category.name
          : 'No Shops in the selected category'
      "
    ></bread-crumb>
    <section class="ps-store-list">
      <div class="ps-container">
        <div class="ps-section__header"></div>
        <div class="ps-section__wrapper">
          <div class="ps-section__left">
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Search</h3>
              <input class="form-control" type="text" placeholder="Search..." />
            </aside>
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Filter by Category</h3>
              <div class="form-group">
                <select class="form-control">
                  <option value="0" selected="selected">
                    Filter by shop category
                  </option>
                </select>
              </div>
            </aside>
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Filter by Location</h3>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search by Town"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search by City"
                />
              </div>
            </aside>
          </div>
          <div class="ps-section__right">
            <div class="ps-shopping__header mt-3">
              <p>
                 Showing <strong>{{ shops.length }}</strong> of <strong> {{ count }}</strong> Products in <strong>{{ category.name }}</strong>
                </p>
                <hr>
            </div>
            <section class="ps-store-box d-flex justify-content-center align-item-center">
              <b-spinner v-if="loading" style="width: 3rem; height: 3rem;" type="grow" variant="warning" rounded="sm"></b-spinner>
              <div v-if="!loading" class="ps-section__content">
                <div class="row">
                  <div
                    v-for="shop in shops"
                    :key="shop.id"
                    class="col-md-3 col-6 col-sm-6"
                  >
                    <Product-card :product="shop"></Product-card>
                  </div>
                </div>
                <div class="ps-pagination">
                  <ul class="pagination text-right" style="position: relative">
                    <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                      <a href="#"><i class="icon-chevron-left"></i></a>
                    </li>
                    <li class="active">
                      <a href="#">Page {{ pageNumber }} </a>
                    </li>
                    <li
                      v-for="link in next3Links"
                      v-on:click="goToPage(link.url)"
                    >
                      <a href="#">{{ link.page }} </a>
                    </li>
                    <li v-on:click="goToPage(lastPage)" v-if="hasNextPage">
                      <a href="#">...{{ totalPages }}</a>
                    </li>
                    <!--                      <li><a href="#">3</a></li>-->
                    <li v-on:click="goToNextPage()" v-if="hasNextPage">
                      <a href="#"><i class="icon-chevron-right"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import global from "@/mixins/global.js";
import ShopCard from "@/components/Cards/ShopCard";
import BreadCrumb from "@/components/Navs/BreadCrumb";
import ProductCard from "../../components/Cards/ProductCard";
import axios from "axios";
export default {
  components: { ShopCard, BreadCrumb, ProductCard },
  data() {
    return {
      shops: [],
      categories: [],
      products: [],
      category: null,
      links: {},
      meta: {},
      url: null,
      nextPage: null,
      prevPage: null,
      hasNextPage: false,
      hasPrevPage: false,
      pageNumber: 1,
      totalPages: 0,
      next3Links: [],
      lastPage: null,
      envUrl: process.env.VUE_APP_API_URL,
      loading: false,
      count: 0,
    };
  },
  mixins: [global],
  mounted() {
    this.getCategory(this.$route.params.slug);
  },
  methods: {
    async getCategory(slug) {
      this.$loading(true);
      this.loading = true;
      if (this.url) {
        await axios.get(`${this.url}`).then((response) => {
          this.shops = response.data.data;
          this.links = response.data.links;
          this.meta = response.data.meta;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          console.log(response.data);
        });
      } else {
        await axios.get(`categories`).then((response) => {
          this.categories = response.data;
          console.log("slug", slug);
          this.category = this.categories.find(
            (category) => category.slug == slug
          );
          console.log(this.category);

          
        });
        await axios
            .get(`products/category/${this.category.id}?limit=18`)
            .then((response) => {
              this.shops = response.data.data;
              this.links = response.data.links;
              this.meta = response.data.meta;
              this.count = response.data.meta.total;
              this.pageNumber = response.data.meta.current_page;
              this.handleNextPage(response.data.links);
              this.handlePrevPage(response.data.links);
              console.log(response.data);
            });
      }
      this.loading = false;
      this.$loading(false);
    },

    scrollTop() {
      let element = document.getElementsByClassName("ps-shopping-product");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },
    goToNextPage: function () {
      this.url = this.nextPage;
      if (this.url) {
        this.getCategory(this.$route.params.slug);
      }
      this.scrollTop();
    },

    goToPrevPage: function () {
      this.url = this.prevPage;
      if (this.url) {
        this.getCategory(this.$route.params.slug);
      }
      this.scrollTop();
    },

    goToPage: function (url) {
      this.url = url;
      console.log("url", url);
      if (this.url) {
        //this.getShop2(this.url);
        this.getCategory(this.$route.params.slug);
      }
      //this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
        this.lastPage = links.last;
        this.totalPages = parseInt(links.last.split("=")[1]);
        this.next3Links = [];

        for (
          let i = this.pageNumber + 1;
          i <= this.pageNumber + 3 && i < this.totalPages;
          i++
        ) {
          this.next3Links.push({
            url: `${this.envUrl}products/category/${this.category.id}?page=${i}`,
            page: i,
          });
        }
      } else {
        this.hasNextPage = false;
        this.next3Links = [];
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.main-banner {
  height: 200px;
}
.search-input {
  width: 100%;
}
.btn-warning {
  width: 200px;
}
</style>
